<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('billing.timeline')"
    class="px-5 py-3"
    v-if="detail"
  >
    <div class="d-flex">
      <billing-timeline-content
        :timeline="detail"
        :permissions="permissions"
      ></billing-timeline-content>
      <div class="timeline--legend">
        <v-row class="align-center">
          <v-col cols="auto">
            <div class="circle paid"></div>
          </v-col>
          <v-col>
            {{ $t('billing.paid') }}
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="auto">
            <div class="circle active"></div>
          </v-col>
          <v-col>
            {{ $t('billing.active') }}
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="auto">
            <div class="circle inactive"></div>
          </v-col>
          <v-col>
            {{ $t('billing.inactive') }}
          </v-col>
        </v-row>
      </div>
    </div>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const BillingTimelineContent = () =>
  import('@/components/transaction-management/transaction/partials/billing-timeline-content');

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  components: {
    BillingTimelineContent,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
    }),
  },
  methods: {
    async deleteAction(id) {
      try {
        await this.$store.dispatch('transaction/delete', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('transaction.delete.successMsg'),
          'success',
        );
        this.$router.back();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
